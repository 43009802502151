<template>
  <div class="main">
    <div class="header">
      <!-- 账户信息摘要 -->
      <div class="left">
        <div class="title">
          {{ $fanyi('账户信息摘要') }}
        </div>
        <div class="content">
          <div class="top">
            <div class="handle_order order_box" @click="
              $fun.toPage('/user/order')

              ">
              <div>
                <countTo :startVal="headercountNumInfo.orderOffer" :endVal="headernewCountNumInfo.orderOffer"
                  :duration="3000">
                </countTo>
              </div>
              <div>{{ $fanyi('订单待处理') }}</div>
            </div>
            <div class="warehouse_order order_box" @click="
              $fun.toPage('/user/order?status=warehouse')

              ">
              <div>
                <countTo :startVal="headercountNumInfo.upComplete" :endVal="headernewCountNumInfo.upComplete"
                  :duration="3000">
                </countTo>
              </div>
              <div style="line-height:13px">
                {{ $fanyi('入库完了订单') }}
              </div>
            </div>
          </div>
          <div class="bottom">
            <div class="bottomLeft">
              <div class="level">

                <img :src="levelImg" alt="" />
              </div>
              <div class="levelMessage">
                <div class="levelMessageTop">
                  {{ $fanyi('佣金比例') }}
                  {{ (userInfo.service_rate * 100).toFixed(1) }}%
                </div>
                <div class="levelMessageCenter">
                  <div :style="'width:' + userInfo.next_level_difference"></div>
                </div>
                <el-link type="primary" @click="poundagePageJump">{{
                  $fanyi('佣金计算方法')
                }}</el-link>
              </div>
            </div>
            <div class="cuponesNumberContainer" @click="$fun.toPage('/user/coupon')">
              <div>{{ $fanyi('优惠券') }}</div>
              <div>{{ userInfo.coupon_number }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="userBalanceContainer">
        <div class="userBalanceLeftContainer">
          <div>{{ $fanyi('账户余额') }}</div>

        </div>
        <div class="icon">
          <img src="../../../../assets/user-img/icon.svg" alt="">
        </div>
        <el-popover placement="bottom" trigger="hover">
          <div style="font-size: 18px;font-weight: bold;color: #ff730b;">
            <span style="font-size: 14px;font-weight: 500;color: #333; margin-right: 2px;" class="drawing">
              {{ $fanyi('提现中') }}:</span>
            ${{ $fun.EURNumSegmentation(userInfo.balance_freezing) }}
          </div>
          <div class="u-line" slot="reference">
            <span class="balance_usable"> ${{ $fun.EURNumSegmentation(userInfo.balance_usable) }}</span>
          </div>
        </el-popover>
        <div class="Cash" @click="$fun.toPage('/user/withdrawl')">
          {{ $fanyi('提现') }}
        </div>

      </div>
      <!-- 重点订单信息 -->
      <div class="right">
        <div class="title">{{ $fanyi('重点订单信息') }}</div>
        <div class="content">
          <div class="one order_list" @click="
            $fun.toPage('/user/order?status=obligation')">
            <div class=" one-left">
              <span>
                <countTo :startVal="headercountNumInfo.orderWaitPay" :endVal="headernewCountNumInfo.orderWaitPay"
                  :duration="3000">
                </countTo>
              </span>
              <span class="txt">{{ $fanyi('需要付款的订单') }}</span>
            </div>
            <div class="one-right">
              <div class="icon"></div>
              <div class="text">{{ $fanyi('管理订单') }}</div>
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
          <div class="two order_list" @click="
            $fun.toPage('/user/packinglist?status=Tobepaid'),
            $store.commit(
              'updateRouterQuery',
              'Tobepaid'
            )

            ">
            <div class="two-left">
              <span>
                <countTo :startVal="headercountNumInfo.porderOffer" :endVal="headernewCountNumInfo.porderOffer"
                  :duration="3000">
                </countTo>
              </span>
              <span class="txt"> {{ $fanyi('待支付国际运费') }}</span>
            </div>
            <div class="two-right">
              <div class="icon"></div>
              <div class="text">{{ $fanyi('管理订单') }}</div>
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
          <div class="there order_list" @click="
            $fun.toPage('/user/packinglist?status=Usdeliveryunderway'),
            $store.commit('updateRouterQuery', 'Usdeliveryunderway')

            ">
            <div class="there-left">
              <span>
                <countTo :startVal="headercountNumInfo.porderForeignTransport"
                  :endVal="headernewCountNumInfo.porderForeignTransport" :duration="3000"></countTo>
              </span>
              <span>
                <div class="txt">
                  {{ $fanyi('美国运输中的订单') }}
                </div>
              </span>
            </div>
            <div class="there-right">
              <div class="icon"></div>
              <div class="text">{{ $fanyi('管理订单') }}</div>
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 点击区域 -->
    <div class="content">
      <div class="opt">
        <div class="title">{{ $fanyi('订单状态概况') }}</div>
        <div class="pageJumpListContainer">
          <div class="pageJumpContainer" @click="
            $fun.toPage('/user/order?status=temporary')
          update()
            ">
            <div class="icon temporary">
              <img src="../../../../assets/user-img/order_1.svg" alt="" />
            </div>
            <div class="right">
              <div class="text">{{ $fanyi('临时保存的订单') }}</div>
              <div class="number">
                <countTo :startVal="countNumInfo.order_tamp" :endVal="newCountNumInfo.order_tamp" :duration="3000" />
              </div>
            </div>
          </div>
          <div class="pageJumpContainer" @click="
            $fun.toPage('/user/order?status=obligation'),
            update()
            ">
            <div class="icon pending">
              <img src="../../../../assets/user-img/order_2.svg" alt="" />
            </div>
            <div class="right">
              <div class="text">{{ $fanyi('待处理订单') }}</div>
              <div class="number">
                <countTo :startVal="countNumInfo.order_un_delivery" :endVal="newCountNumInfo.order_un_delivery"
                  :duration="3000" />
              </div>
            </div>
          </div>
          <div class="pageJumpContainer" @click="
            $fun.toPage('/user/order?status=received'),
            update()
            ">
            <div class="icon RECEPCIONPENDIENTE">
              <img src="../../../../assets/user-img/order_3.svg" alt="" />
            </div>
            <!--          待收货订单-->
            <div class="right">
              <div class="text">{{ $fanyi('等待工厂发货的订单') }}</div>
              <div class="number">
                <countTo :startVal="countNumInfo.order_purchase_uncomplete"
                  :endVal="newCountNumInfo.order_purchase_uncomplete" :duration="3000" />
              </div>
            </div>
          </div>
          <div class="pageJumpContainer" style="margin: 0 0 20px 0;" @click="
            $fun.toPage('/user/order?status=inspection'),

            update()
            ">
            <div class="icon CONTROLDECALIDAD">
              <img src="../../../../assets/user-img/order_4.svg" alt="" />
            </div>
            <!--          检品中订单-->
            <div class="right">
              <div class="text">{{ $fanyi('质量控制和处理') }}</div>
              <div class="number">
                <countTo :startVal="countNumInfo.order_inspecting" :endVal="newCountNumInfo.order_inspecting"
                  :duration="3000" />
              </div>
            </div>
          </div>
        </div>
        <div class="pageJumpListContainer" @click="
          $fun.toPage('/user/order?status=warehouse'),

          update()
          ">
          <div class="pageJumpContainer" style="margin: 0 20px 20px 0;">
            <div class="icon WAREHOUSE">
              <img src="../../../../assets/user-img/order_5.svg" alt="" />
            </div>
            <!--          在RAKUMART仓库中订单-->
            <div class="right">
              <div class="text">{{ $fanyi('货物到达RAKUMART中国仓库') }}</div>
              <div class="number">
                <countTo :startVal="countNumInfo.order_up_complete" :endVal="newCountNumInfo.order_up_complete"
                  :duration="3000" />
              </div>
            </div>
          </div>

          <div class="pageJumpContainer" @click.stop="
            $fun.toPage('/user/packinglist?status=internationalFreightToBeQuotedDeliverySheet'),

            update()
            ">
            <div class="icon FLETEPENDIENTECOTIZACION">
              <img src="../../../../assets/user-img/order_6.svg" alt="" />
            </div>
            <!--          国际运费待报价配送单-->
            <div class="right">
              <div class="text">{{ $fanyi('国际运费报价') }}</div>
              <div class="number">
                <countTo :startVal="countNumInfo.porder_wait_offer" :endVal="newCountNumInfo.porder_wait_offer"
                  :duration="3000" />
              </div>
            </div>
          </div>

          <div class="pageJumpContainer" @click.stop="
            $fun.toPage('/user/packinglist?status=Tobepaid')">
            <div class="icon ENVIOPENDIENTEDEPAGO">
              <img src="../../../../assets/user-img/order_7.svg" alt="" />
            </div>
            <!--          国际运费待支付配送单-->
            <div class="right">
              <div class="text">{{ $fanyi('国际运费待支付') }}</div>
              <div class="number">
                <countTo :startVal="countNumInfo.porder_wait_pay" :endVal="newCountNumInfo.porder_wait_pay"
                  :duration="3000" />
              </div>
            </div>
          </div>
          <div class="pageJumpContainer" style="margin: 0 0 20px 0;" @click.stop="
            $fun.toPage('/user/packinglist?status=orderForDelivery'),
            update()
            ">
            <div class="icon ESPERADERECOGIDA">
              <img src="../../../../assets/user-img/order_8.svg" alt="" />
            </div>
            <!--          待发货配送单-->
            <div class="right">
              <div class="text">{{ $fanyi('已入仓待装箱发货') }}</div>
              <div class="number">
                <countTo :startVal="countNumInfo.porder_pay_un_delivery"
                  :endVal="newCountNumInfo.porder_pay_un_delivery" :duration="3000" />
              </div>
            </div>
          </div>
        </div>
        <div class="pageJumpListContainer" @click="
          $fun.toPage('/user/packinglist?status=deliveryInTransit'),
          update()
          ">
          <div class="pageJumpContainer">
            <div class="icon ENTRANSITOHACIAESPAÑA">
              <img src="../../../../assets/user-img/order_9.svg" alt="" />
            </div>
            <!--          运输中配送单-->
            <div class="right">
              <div class="text">{{ $fanyi('中国发往美国的订单') }}</div>
              <div class="number">
                <countTo :startVal="countNumInfo.porder_in_sea" :endVal="newCountNumInfo.porder_in_sea"
                  :duration="3000" />
              </div>
            </div>
          </div>
          <!--        <div class="pageJumpContainer" @click="$router.push({ name: 'Packinglist',query:{status:'deliveryFormToRAKUMARTTransportCentre'}});update()">-->
          <!--          <div class="icon ALMACENESPAÑA"><img src="../../../../assets/user-img/order_10.svg" alt=""></div>-->
          <!--&lt;!&ndash;          到达RAKUMART运输中心配送单&ndash;&gt;-->
          <!--          <div class="right">-->
          <!--            <div class="text">{{$fanyi("货物到达RAKUMART美国仓库")}}</div>-->
          <!--            <div class="number"><countTo :startVal='countNumInfo.porder_cleared' :endVal='newCountNumInfo.porder_cleared' :duration='3000' /></div>-->
          <!--          </div>-->
          <!--        </div>-->
          <div class="pageJumpContainer" @click.stop="
            $fun.toPage('/user/packinglist?status=Usdeliveryunderway'), update()
            ">
            <div class="icon ENVIONACIONALENCURSO">
              <img src="../../../../assets/user-img/order_10.svg" alt="" />
            </div>
            <!--          西班牙派送配送单-->
            <div class="right">
              <div class="text">{{ $fanyi('美国派送中') }}</div>
              <div class="number">
                <countTo :startVal="countNumInfo.porder_foreign_express"
                  :endVal="newCountNumInfo.porder_foreign_express" :duration="3000" />
              </div>
            </div>
          </div>
          <div class="pageJumpContainer" style="margin: 0 0 20px 0;" @click.stop="
            $fun.toPage('/user/packinglist?status=Signedandreceived'), update()">
            <div class="icon ENTREGADO">
              <img src="../../../../assets/user-img/order_11.svg" alt="" />
            </div>
            <!--已签收配送单 -->
            <div class="right">
              <div class="text">{{ $fanyi('已收货') }}</div>
              <div class="number">
                <countTo :startVal="countNumInfo.porder_receive" :endVal="newCountNumInfo.porder_receive"
                  :duration="3000" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import countTo from 'vue-count-to'

export default {
  data() {
    return {
      levelImg: '',

      // 默认数据
      headercountNumInfo: {
        unDelivery: 0, //待处理订单
        upComplete: 0, //上架完了的订单
        orderOffer: 0, //待支付订单
        orderWaitPay: 0,
        porderOffer: 0, //待支付国际运费的配送单
        porderForeignTransport: 0, //西班牙运输中的配送单
      },
      headernewCountNumInfo: {
        unDelivery: 0, //待处理订单
        upComplete: 0, //上架完了的订单
        orderWaitPay: 0,
        orderOffer: 0, //待支付订单
        porderOffer: 0, //待支付国际运费的配送单
        porderForeignTransport: 0, //西班牙运输中的配送单
        order_tamp: 0,
      },
      countNumInfo: {
        order_tamp: 0, //临时订单
        order_un_delivery: 0, //待处理订单(未完全发出)
        order_purchase_uncomplete: 0, //购买中
        order_up_complete: 0, //货物上架完成 (未完全发出)
        order_inspecting: 0, //检品中
        porder_wait_offer: 0, //配送单待报价
        porder_wait_pay: 0, //配送单待支付
        porder_pay_un_delivery: 0, //已支付待发货
        porder_in_sea: 0, //国际运输中
        porder_cleared: 0, //已通过
        porder_foreign_express: 0, //外国运输中
        porder_receive: 0, //已签收
      },
      newCountNumInfo: {
        order_tamp: 0, //临时订单
        order_un_delivery: 0, //待处理订单(未完全发出)
        order_purchase_uncomplete: 0, //购买中
        order_up_complete: 0, //货物上架完成 (未完全发出)
        order_inspecting: 0, //检品中
        porder_wait_offer: 0, //配送单待报价
        porder_wait_pay: 0, //配送单待支付
        porder_pay_un_delivery: 0, //已支付待发货
        porder_in_sea: 0, //国际运输中
        porder_cleared: 0, //已通过
        porder_foreign_express: 0, //外国运输中
        porder_receive: 0, //已签收
      },
    }
  },

  computed: {
    userInfo() {
      return this.$store.state.userInfo
    },

  },
  components: {
    countTo,
  },
  created() {
    this.levelImg = require(`@/assets/foot-img/commission${this.userInfo.level_name}.svg`)
    this.getHeadCountNum()
    this.getContentCountNum()
  },
  methods: {
    // 获取个人中心看板统计底部统计数据
    async getHeadCountNum() {
      const res = await this.$api.headCountNum()
      console.log(res);

      this.headernewCountNumInfo = res.data
    },

    poundagePageJump() {
      let routeUrl = router.resolve({
        path: '/commission',
      })
      window.open(routeUrl.href)
    },
    // 获取个人中心看板统计底部统计数据
    getContentCountNum() {
      this.$api
        .contentCountNum()
        .then((res) => {
          this.newCountNumInfo = res.data
        })
        .catch((err) => { })
    },
  },
}
</script>
<style lang="scss" scoped>
.main {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    .left {
      width: 380px;
      height: 212px;
      background: #ffffff;
      border-radius: 6px;
      border: 1px solid #e2e2e2;
      padding: 20px;


      .title {
        font-size: 14px;
        font-weight: 600;
        color: #222;

      }

      .content {
        margin-top: 20px;

        .top {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .order_box {
            width: 160px;
            height: 60px;
            cursor: pointer;
            color: #fff;
            border-radius: 6px;
            display: flex;
            align-items: center;
            padding: 15px;


            div:first-child {
              font-weight: 600;
              font-size: 24px;
              margin-right: 10px;
            }

            div:last-child {
              font-weight: 400;
              font-size: 14px;
            }
          }

          .handle_order {
            background: #00c18b;
            border: none;
          }

          .warehouse_order {
            background: #698cff;
            border: none;
          }
        }

        .bottom {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 20px;

          .bottomLeft {
            display: flex;
            align-items: center;

            .level {
              width: 26px;
              height: 26px;

              border-radius: 2px;
              color: #fff;
              text-align: center;
              line-height: 24px;
              font-size: 12px;
              margin-right: 10px;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .levelMessage {
              .levelMessageTop {
                font-size: 14px;
                margin-bottom: 10px;
              }

              .levelMessageCenter {
                width: 221px;
                height: 4px;
                margin-bottom: 5px;
                background-color: #f0f0f0;

                div {
                  width: 55%;
                  height: 100%;
                  background: #ff730b;
                }
              }

              /deep/ .el-link {
                font-size: 12px;
                color: #ff730b !important;
              }

              /deep/ .el-link.el-link--primary:after {
                border-color: #ff730b !important;
              }
            }
          }

          .cuponesNumberContainer {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            cursor: pointer;

            div:first-child {
              font-size: 14px;
              margin-bottom: 8px;
            }

            div:last-child {
              font-weight: 600;
              font-size: 18px;
            }
          }
        }
      }
    }

    .userBalanceContainer {
      width: 240px;
      height: 212px;
      padding: 20px;
      background: #FFFFFF;
      border-radius: 6px;
      border: 1px solid #E2E2E2;

      margin: 0 20px;

      .userBalanceLeftContainer {
        margin-bottom: 20px;

        div {
          font-weight: 500;
          font-size: 14px;
          color: #222222;
          line-height: 20px;
          text-align: left;
          font-style: normal;
        }
      }

      .Cash {
        width: 200px;
        height: 40px;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 500;
        border-radius: 4px;
        border: 1px solid #FF730B;
        color: #ff730b;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-top: 10px;

        &:hover {
          color: #fff;
          background-color: #ff730b;
        }
      }

      .icon {
        width: 40px;
        height: 40px;
        background: #FFFFFF;
        box-shadow: 0px 0 3px 0px rgba(0, 0, 0, 0.08);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-bottom: 10px;
      }

      .balance_usable {
        font-weight: bold;
        font-size: 24px;
        color: #222222;
        line-height: 32px;

        text-align: left;
        font-style: normal;
      }
    }



    .right {
      width: 520px;
      height: 212px;
      background: #ffffff;
      padding: 20px;
      box-sizing: border-box;
      border-radius: 6px;
      border: 1px solid #e2e2e2;

      .title {
        font-weight: 600;
        font-size: 14px;
        color: #222222;
        margin-bottom: 20px;
      }

      .content {
        .order_list {
          width: 480px;
          height: 40px;

          border-radius: 6px;
          padding: 6px;
          padding-right: 9px;
          margin-bottom: 6px;
          display: flex;
          align-items: center;
          justify-content: space-between;

        }

        .one {
          background: #FFF2E8;

          .one-left {
            display: flex;
            align-items: center;
            height: 100%;

            .txt {
              font-size: 14px;
              display: inline-block;
              font-family: Roboto-Regular, Roboto;
              font-weight: 400;
              color: #222222;
              margin-left: 17px;

              &:hover {
                cursor: pointer;
              }
            }

            span {
              &:first-child {
                width: 32px;
                height: 32px;
                background: #ff730b;
                border-radius: 4px;
                background: #ff730b;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                font-family: Roboto-Bold, Roboto;
                font-weight: bold;
                color: #ffffff;
              }
            }
          }

          .one-right {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .icon {
              width: 8px;
              height: 8px;
              background: #ff730b;
              border-radius: 50%;
              margin-top: 3px;
            }

            .text {
              margin-left: 6px;
              margin-right: 5px;
              font-size: 14px;
              font-family: Roboto-Regular, Roboto;
              font-weight: 400;
              color: #222222;

              &:hover {
                cursor: pointer;
              }
            }

            /deep/ .el-icon-arrow-right {
              margin-top: 2px;
              color: #000000;
              font-size: 14px;
            }
          }
        }

        .two {
          background: #fbe5e3;

          .two-left {
            display: flex;
            align-items: center;
            height: 100%;

            .txt {
              font-size: 14px;
              display: inline-block;
              font-family: Roboto-Regular, Roboto;
              font-weight: 400;
              color: #222222;
              margin-left: 17px;

              &:hover {
                cursor: pointer;
              }
            }

            span {
              &:first-child {
                width: 32px;
                height: 32px;
                background: #e74c3c;
                border-radius: 4px;

                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                font-family: Roboto-Bold, Roboto;
                font-weight: bold;
                color: #ffffff;
              }
            }
          }

          .two-right {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .icon {
              width: 8px;
              height: 8px;
              background: #e74c3c;
              border-radius: 50%;
              margin-top: 3px;
            }

            .text {
              margin-left: 6px;
              margin-right: 5px;
              font-size: 14px;
              font-family: Roboto-Regular, Roboto;
              font-weight: 400;
              color: #222222;

              &:hover {
                cursor: pointer;
              }
            }

            /deep/ .el-icon-arrow-right {
              margin-top: 2px;
              color: #000000;
              font-size: 14px;
            }
          }
        }

        .there {
          background: #d8fbf1;
          margin-bottom: 0;

          .there-left {
            display: flex;
            align-items: center;
            height: 100%;

            .txt {
              font-size: 14px;
              display: inline-block;
              font-family: Roboto-Regular, Roboto;
              font-weight: 400;
              color: #222222;
              margin-left: 17px;

              &:hover {
                cursor: pointer;
              }
            }

            span {
              &:first-child {
                width: 32px;
                height: 32px;

                border-radius: 4px;
                background: #00c18b;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                font-family: Roboto-Bold, Roboto;
                font-weight: bold;
                color: #ffffff;
              }
            }
          }

          .there-right {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .icon {
              width: 8px;
              height: 8px;
              background: #00c18b;
              border-radius: 50%;
              margin-top: 3px;
            }

            .text {
              margin-left: 6px;
              margin-right: 5px;
              font-size: 14px;
              font-family: Roboto-Regular, Roboto;
              font-weight: 400;
              color: #222222;

              &:hover {
                cursor: pointer;
              }
            }

            /deep/ .el-icon-arrow-right {
              margin-top: 2px;
              color: #000000;
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .content {
    .opt {
      width: 1180px;
      margin-bottom: 20px;

      .title {
        font-size: 16px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #222222;
        line-height: 24px;
        margin-bottom: 21px;
      }

      .pageJumpListContainer {
        display: flex;
        align-items: center;

        .pageJumpContainer {
          width: 280px;
          height: 124px;
          background: #ffffff;
          border-radius: 6px;
          border: 1px solid #e2e2e2;
          background: #ffffff;
          border: 1px solid #e3e3e3;
          margin: 0 20px 20px 0;
          padding: 30px 0 23px 20px;
          display: flex;
          align-items: center;
          cursor: pointer;

          .icon {
            width: 64px;
            height: 64px;
            background: #fff2e8;
            border-radius: 50%;
            margin-right: 10px;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .temporary {
            img {
              width: 36px;
              height: 36px;
            }
          }

          .pending {
            padding-right: 3px;

            img {
              width: 36px;
              height: 36px;
            }
          }

          .RECEPCIONPENDIENTE {
            img {
              width: 36px;
              height: 36px;
            }
          }

          .WAREHOUSE {
            img {
              width: 36px;
              height: 36px;
            }
          }

          .CONTROLDECALIDAD {
            img {
              width: 36px;
              height: 36px;
            }
          }

          .FLETEPENDIENTECOTIZACION {
            img {
              width: 36px;
              height: 36px;
            }
          }

          .ENVIOPENDIENTEDEPAGO {
            img {
              width: 36px;
              height: 36px;
            }
          }

          .ESPERADERECOGIDA {
            img {
              width: 36px;
              height: 36px;
            }
          }

          .ENTRANSITOHACIAESPAÑA {
            img {
              width: 36px;
              height: 36px;
            }
          }

          .ALMACENESPAÑA {
            img {
              width: 36px;
              height: 36px;
            }
          }

          .ENVIONACIONALENCURSO {
            img {
              width: 36px;
              height: 36px;
            }
          }

          .ENTREGADO {
            img {
              width: 36px;
              height: 36px;
            }
          }

          .right {
            .number {
              color: #2f2f2f;
              font-weight: 600;
              font-size: 24px;
            }

            .text {
              color: #222222;
              width: 160px;
              font-size: 14px;
              margin-bottom: 7px;
            }
          }
        }
      }
    }
  }

}
</style>
